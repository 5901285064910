@import "settings/theme-variables";
/**  =====================
Rtl css start
==========================  **/
body.able-pro-rtl {
  direction: rtl;
  text-align: right;
  // position: unset;
  .scroll-div {
    > .scroll-element {
      &.scroll-y {
        right: auto;
        left: 6px;
      }
    }
  }
  .search-bar .close {
    right: auto;
    left: 9px;
  }
  .card {
    .card-header {
      h5 {
        margin-right: 0;
        margin-left: 10px;

        &:after {
          left: auto;
          right: -25px;
        }
      }

      .card-header-right {
        display: none;
        left: 10px;
        right: auto;
      }
    }
  }
  /* ======    Header   ========== */
  /* ======    Bootstrap   ========== */
  .mr-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .float-left {
    float: right !important;
  }

  .float-right {
    float: left !important;
  }

  .text-right {
    text-align: left !important;
  }

  .text-left {
    text-align: right !important;
  }

  .list-group {
    padding-right: 0;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-left: 8px;
  }
  /* ======    Bootstrap   ==========*/
  /**  =====================
    Generic-class css start
    ========================== **/
  $i: 0;
  @while $i<=50 {
    .p {
      &-l-#{$i} {
        padding-right: #{$i}px;
        padding-left: 0;
        @if ($i == 0) {
          &[class*="col"] {
            padding-left: 15px;
          }
        }
      }

      &-r-#{$i} {
        padding-left: #{$i}px;
        padding-right: 0;
        @if ($i == 0) {
          &[class*="col"] {
            padding-right: 15px;
          }
        }
      }
    }

    .m {
      &-l-#{$i} {
        margin-right: #{$i}px;
        margin-left: 0;
      }

      &-r-#{$i} {
        margin-left: #{$i}px;
        margin-right: 0;
      }
    }
    $i: $i + 5;
  }
  /*====== Padding , Margin css ends ======*/
  .pcoded-header {
    .input-group {
      .input-group-text {
        margin-right: auto;
        margin-left: 0;
      }
    }

    .navbar-nav {
      padding-right: 0;

      > li {
        &:first-child {
          padding-right: 25px;
          padding-left: 12px;
        }

        &:last-child {
          padding-left: 30px;
          padding-right: 12px;
        }
      }
    }

    .mr-auto {
      .dropdown-menu {
        margin-left: auto;
        margin-right: -20px;
      }
    }

    .ml-auto {
      .dropdown-menu {
        margin-right: auto;
        margin-left: -20px;
      }
    }

    .main-search.open .input-group {
      padding: 0 20px 0 0;

      .search-btn {
        margin-left: auto;
        margin-right: 5px;
      }
    }

    &[class*="header-"] {
      .main-search {
        &.open {
          .input-group {
            padding: 2px 20px 2px 2px;

            .search-btn {
              margin-left: auto;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .dropdown {
      .dropdown-toggle {
        padding-right: 0;
        /*padding-left: 15px;  amit */
        &:after {
          right: 12px;
        }
      }

      &.show {
        &:before {
          left: 0;
          right: -5px;
        }
      }

      .notification {
        .noti-body {
          img {
            margin-right: auto;
            margin-left: 20px;
          }

          li {
            .n-time {
              float: left;
            }
          }
        }

        ul {
          padding-right: 0;
        }
      }

      .profile-notification {
        .pro-head {
          img {
            margin-right: auto;
            margin-left: 10px;
          }

          .dud-logout {
            padding-left: 0;
            right: auto;
            left: 0;
          }
        }
      }
    }

    .m-header {
      .mobile-menu {
        left: auto;
        right: 0;
        @media only screen and (min-width: 992px) {
          right: auto;
          left: 0;
        }
      }

      .mob-toggler {
        right: auto;
        left: 0;
      }
    }
  }
  /* ======    Header   ========== */
  /* ======    menu   ========== */
  .b-brand {
    .b-title {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .pcoded-navbar {
    direction: rtl;
    text-align: right;

    ul {
      padding-left: 0;
      padding-right: 0;
    }

    .mobile-menu {
      right: auto;
      left: 10px;
    }

    .pcoded-badge {
      margin-right: 10px;
    }

    &.theme-horizontal {
      .pcoded-inner-navbar
        > app-nav-group
        > app-nav-collapse
        > li.pcoded-hasmenu.active:before,
      .pcoded-inner-navbar
        > app-nav-group
        > app-nav-collapse
        > li.pcoded-hasmenu.pcoded-trigger:before,
      .pcoded-inner-navbar app-nav-item > li.pcoded-hasmenu.active:before,
      .pcoded-inner-navbar
        app-nav-item
        > li.pcoded-hasmenu.pcoded-trigger:before {
        padding-right: calc(50% - 25px);
        padding-left: 0;
      }
    }

    .pcoded-inner-navbar {
      li {
        > a {
          text-align: right;
        }

        &.pcoded-hasmenu {
          > a:after {
            right: auto;
            left: 20px;
          }

          .pcoded-submenu {
            &:after {
              left: auto;
              right: 25px;
            }

            li {
              &:after {
                left: auto;
                right: 26px;
              }

              > a {
                text-align: right;
                padding: 7px 60px 7px 7px;

                &:before {
                  left: auto;
                  right: 44px;
                }
              }

              .pcoded-submenu > app-nav-item > li {
                > a {
                  padding: 7px 80px 7px 7px;

                  &:before {
                    left: auto;
                    right: 65px;
                  }
                }

                &:after {
                  left: auto;
                  right: 45px;
                }
              }

              .pcoded-submenu {
                &:after {
                  left: auto;
                  right: 45px;
                }

                > li {
                  &:after {
                    left: auto;
                    right: 46px;
                  }

                  > a {
                    padding: 7px 80px 7px 7px;

                    &:before {
                      left: auto;
                      right: 65px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      > li {
        a {
          .pcoded-micon {
            margin-right: 0;
            margin-left: 7px;
          }
        }

        &.active,
        &.pcoded-trigger {
          &:after {
            left: auto;
            right: 0;
          }
        }
      }
    }

    &.navbar-collapsed {
      .header-logo {
        .logo-thumb {
          left: auto;
          right: calc((#{$Menu-collapsed-width} / 2) - 20px);
        }
      }

      .pcoded-menu-caption {
        &:after {
          left: auto;
          right: 15px;
        }
      }

      ~ .pcoded-main-container {
        margin-left: auto;
        margin-right: $Menu-collapsed-width;
      }

      &:hover {
        .header-logo {
          .mobile-menu {
            right: auto;
            left: 0;
          }
        }

        .pcoded-inner-navbar {
          direction: rtl;
        }
      }

      .pcoded-inner-navbar {
        direction: ltr;

        > app-nav-group > app-nav-collapse > li,
        > app-nav-group > app-nav-item > li {
          &.pcoded-hasmenu {
            > a {
              &:after {
                right: auto;
                left: 12px;
              }
            }
          }
        }

        > li.pcoded-hasmenu > a:after {
          left: 12px;
          right: auto;
        }
      }

      &:not(:hover) {
        .pcoded-menu-caption {
          padding: 18px 0 5px;
        }

        .pcoded-inner-navbar li.pcoded-hasmenu {
          .pcoded-submenu {
            width: $Menu-collapsed-width;

            li > a {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }

    &.mob-open,
    &.navbar-collapsed:hover {
      ~ .pcoded-main-container {
        margin-left: auto;
        margin-right: $Menu-collapsed-width;

        &:before {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .pcoded-main-container {
    margin-left: 0;
    margin-right: $Menu-width;
  }
  /* ======    menu   ========== */
  /* ======    dropdown   ========== */
  .dropdown {
    .dropdown-menu {
      text-align: right;
      left: auto;

      &.dropdown-menu-right {
        right: auto;
        left: 0;
      }
    }
  }

  .scrollable-body {
    padding: 0 !important;

    perfect-scrollbar {
      .p-r-0[class*="col"] {
        padding-right: 30px;
      }
    }
  }

  .note-card .note-box-aside {
    border-left: 1px solid $theme-border;
    border-right: none;
  }
  /* ======    dropdown   ========== */
  /* ======    Header Chat   ========== */
  .header-chat,
  .header-user-list {
    right: auto;
    left: -350px;

    &.open {
      right: auto;
      left: 0;
    }
  }

  .header-user-list {
    &.open {
      .h-close-text {
        left: auto;
        right: -30px;
        transform: rotate(180deg);
        top: 167px;
      }
    }
  }

  .header-chat {
    .h-list-header {
      .h-back-user-list {
        left: auto;
        right: 0;
        transform: rotate(180deg);
      }
    }

    .h-list-body {
      .chat-messages {
        .chat-menu-reply {
          text-align: left;
        }
      }
    }

    .h-list-footer {
      .input-group {
        .btn-attach {
          margin-right: 0;
          margin-left: 5px;

          > i {
            margin-left: 0;
          }
        }

        .btn-send {
          margin-left: 0;
          margin-right: 5px;
          right: auto;
          left: -45px;

          i {
            margin-left: 0;
          }
        }
      }
    }
  }
  /* ======    Header Chat   ========== */
  /* ======    Menu Styler Start     ======== */
  $style-block-width: 400px;

  .menu-styler {
    h6 {
      &:after {
        right: 0;
        left: auto;
      }
    }

    .style-toggler {
      > a {
        right: auto;
        left: 0;

        &:before {
          right: auto;
          left: 0;
          border-radius: 0 3px 3px 0;
          box-shadow: 0 0 8px transparentize($danger-color, 0.1),
            -6px 0 8px rgba(0, 0, 0, 0.1);
        }

        &:after {
          content: "\e847";
          right: auto;
          left: 11px;
        }
      }
    }

    .prebuild-toggler {
      > .prebuild-group {
        transform: rotate(-90deg);
        right: auto;
        left: -100px;

        > a {
          box-shadow: 0 0 2px transparentize($purple-color, 0.1),
            -6px 0 8px rgba(0, 0, 0, 0.1);
        }
      }
    }

    &.open,
    &.prebuild-open {
      .style-toggler {
        > a {
          right: auto;
          left: $style-block-width;

          &:before {
            right: auto;
          }

          &:after {
            content: "\e849";
            right: auto;
          }
        }
      }

      .prebuild-toggler > .prebuild-group {
        right: auto;
        left: calc(#{$style-block-width} - 100px);
      }
    }

    .style-block,
    .style-block-prebuild {
      right: auto;
      left: -#{$style-block-width};
    }

    &.open {
      .style-block {
        right: auto;
        left: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }
    }

    &.prebuild-open {
      .style-block-prebuild {
        right: auto;
        left: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }
    }

    .theme-color {
      > a {
        margin-right: 0;
        margin-left: 5px;

        span {
          &:before {
            left: auto;
            right: 0;
          }

          &:after {
            right: auto;
            left: 0;
          }
        }

        &.active {
          &:before {
            left: auto;
            right: 14px;
          }

          &:after {
            left: auto;
            right: 0;
          }
        }
      }

      &.small {
        > a {
          &.active {
            &:before {
              right: 5px;
            }
          }
        }
      }
    }
  }
  /* ======    Menu Styler end     ======== */
  /* ======    Radio & Checked Start     ======== */
  .checkbox {
    margin-right: 0;
    margin-left: 5px;

    input[type="checkbox"] {
      + .cr {
        padding-right: 0;

        &:before {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }

    &.checkbox-fill {
      input[type="checkbox"] {
        + .cr {
          &:after {
            margin-right: 0;
            margin-left: 10px;
            left: auto;
            right: 3px;
          }
        }
      }
    }
  }

  .radio {
    margin-right: 0;
    margin-left: 5px;

    input[type="radio"] {
      + .cr {
        padding-right: 0;

        &:after,
        &:before {
          margin-right: 0;
          margin-left: 10px;
        }

        &:after {
          left: auto;
          right: 5px;
        }
      }
    }

    &.radio-fill {
      input[type="radio"] {
        + .cr {
          &:after {
            left: auto;
            right: 2px;
          }
        }
      }
    }
  }

  .menu-styler .radio input[type="radio"] + .cr {
    margin-right: 0;
  }

  .custom-control {
    padding-right: 1.5rem;
    padding-left: 0;

    .custom-control-label {
      &:after,
      &:before {
        left: auto;
        right: -1.5rem;
      }
    }
  }

  .task-list {
    &:after {
      right: 30px;
    }

    li {
      padding-right: 55px;
      padding-left: 0;
    }

    .task-icon {
      right: 22px;
    }
  }

  .dashboard-kit li {
    margin-left: 3px;
    margin-right: 0;
  }
  /**====== Radio & Checked css end ======**/
  /* ==========================    Rsponsive Menu rtl  start   ====================== */
  @media only screen and (max-width: 991px) {
    .pcoded-header {
      .mobile-menu {
        right: auto;
        left: 0;
      }

      .mobile-menu {
        left: 20px;
        right: auto;
      }
    }

    .pcoded-navbar {
      margin-left: 0;
      margin-right: -#{$Menu-width};
      // position: fixed;
      ~ .pcoded-header,
      ~ .pcoded-main-container {
        margin-right: 0;
      }

      &.mob-open {
        margin-right: 0;

        ~ .pcoded-header,
        ~ .pcoded-main-container {
          margin-right: 0;
        }
      }
    }
  }
  /* ==========================    Rsponsive Menu rtl  end   ====================== */
  /* ==========================    basic componant rtl  start   ==================== */
  .alert-dismissible {
    padding-left: 40px;
    padding-right: 20px;

    .close {
      right: auto;
      left: 0;
    }
  }

  .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.255em;
    right: 8px;
    left: auto;
  }

  .btn-group {
    > .btn-group:not(:last-child) > .btn,
    > .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    > .btn-group:not(:first-child) > .btn,
    > .btn:not(:first-child) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .btn-group {
    .btn {
      margin: 0;
    }
  }

  .btn-group,
  .btn-group-vertical {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
      margin-left: 0;
      margin-right: -1px;
    }
  }

  .dropdown-toggle-split,
  .dropright .dropdown-toggle-split,
  .dropup .dropdown-toggle-split {
    &:after {
      margin-right: 0;
    }
  }

  .dropdown-toggle-split:after {
    right: 0 !important;
  }

  [class*="language"] {
    direction: ltr;
  }

  .nav-tabs {
    padding-right: 0;
  }

  .list-inline,
  .list-unstyled {
    padding-right: 0;
  }

  .modal-header .close {
    margin: -1rem auto -1rem -1rem;
  }

  .btn {
    margin-right: 0;
    margin-left: 10px;
  }

  .label {
    margin-right: 0;
    margin-left: 5px;
  }

  .input-group {
    .btn {
      margin-left: 0;
    }

    > .custom-file,
    > .form-control,
    > .custom-select {
      + .custom-file,
      + .custom-select,
      + .form-control {
        margin-left: 0;
        margin-right: -1px;
      }
    }

    > .custom-select,
    > .form-control {
      &:not(:last-child) {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    > .custom-file {
      &:not(:last-child) .custom-file-label,
      &:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:first-child) .custom-file-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }
  }

  .input-group-append,
  .input-group-prepend {
    .btn + .btn,
    .btn + .input-group-text,
    .input-group-text + .btn,
    .input-group-text + .input-group-text {
      margin-left: 0;
      margin-right: -1px;
    }
  }

  .input-group-prepend {
    margin-right: 0;
    margin-left: -1px;
  }

  .input-group-append {
    margin-left: 0;
    margin-right: -1px;
  }

  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .custom-file-label {
    width: 100%;

    &:after {
      right: auto;
      left: 0;
      border-left: none;
      border-right: 1px solid #ced4da;
    }
  }

  table > tbody > tr > td > span.footable-toggle {
    margin-right: 0;
    margin-left: 8px;
  }

  .select-card select {
    display: none;
  }

  .minicolors-slider {
    right: 152px;
  }

  .minicolors-opacity-slider {
    right: 173px;
  }
  /* ==========================    basic componant rtl  end   ====================== */
  /* =========   chart start  ============= */
  .dial-chart {
    direction: ltr;
  }
  /* =========   chart end ============= */
  /* =========   Extra pages Start ============= */
  .job-meta-data {
    i {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  .h-list-body {
    .userlist-box {
      .live-status {
        left: 20px;
        right: auto;
      }

      .media-left {
        padding-left: 10px;
        padding-right: 0;
      }
    }

    .chat-messages {
      .chat-menu-reply {
        text-align: left;

        > div {
          p {
            margin-left: 25px;
            margin-right: 0;
          }

          &:before {
            right: auto;
            left: 19px;
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-left-color: #fff;
            border-top-color: #fff;
            box-shadow: 1px 5px 10px -3px rgba(62, 57, 107, 0.15);
          }
        }

        .chat-time {
          margin: 9px 10px 0 8px;
        }
      }

      .photo-table {
        padding-right: 0;
        padding-left: 15px;
      }

      .chat-menu-content {
        > div {
          &:before {
            left: auto;
            right: -5px;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: $primary-color;
            border-right-color: $primary-color;
            box-shadow: 4px 0 8px -5px rgba(62, 57, 107, 0.3);
          }
        }

        .chat-time {
          margin: 9px 10px 0 8px;
        }
      }
    }
  }

  .note {
    float: right;
  }

  .task-board-left {
    .user-box {
      .media-left {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    .btn {
      margin: 0;
    }
  }

  .task-attachment {
    .file-attach {
      margin-right: 0;
      margin-left: 20px;
    }
  }

  .btn-msg-send {
    margin: 0;
  }

  .assign-user,
  .task-comment {
    .media-left {
      margin-right: 0 !important;
      margin-left: 16px;
    }
  }

  .task-comment {
    .btn {
      margin: 0;
    }
  }

  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_filter {
          margin-top: 10px;

          input {
            margin-right: 7px;
          }

          label {
            float: left;
          }
        }
      }
    }
  }

  .form-material .right-icon-control .form-icon {
    right: auto;
    left: 0;

    .btn {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  .note-bar,
  .widget-lorem,
  .widget-timeline,
  .widget-todo {
    .photo-table {
      margin-right: 0 !important;
      margin-left: 16px;
    }
  }

  .to-do {
    .to-do-button {
      right: auto;
      left: 22px;
    }
  }

  .ticket-customer {
    i {
      right: auto;
      left: 32px;
    }
  }

  .card-event {
    i {
      right: auto;
      left: 27px;
    }
  }

  .bitcoin-wallet {
    i {
      right: auto;
      left: 50px;
    }
  }

  .Active-visitor .card-active > div + div,
  .card-social .card-active > div + div {
    border-right: 1px solid $theme-border;
    border-left: 0 !important;
  }

  .br-theme-bars-reversed {
    .br-widget {
      .br-current-rating {
        text-align: left;
      }
    }
  }

  .jstree-default {
    .jstree-node {
      background-position: -320px -4px;
    }
  }

  .sw-theme-dots {
    > ul.step-anchor {
      > li {
        > a {
          &:after {
            right: 42%;
          }

          &:before {
            right: 40%;
          }
        }
      }
    }
  }

  .dtp {
    > .dtp-content {
      right: 50%;
      margin-right: -150px;
    }
  }

  .note-card {
    .Note-delete {
      left: 10px;
    }
  }

  .filter-bar {
    .nav {
      padding-right: 0;
    }
  }

  .footable {
    .pagination {
      padding: 0;
    }
  }

  .form-check {
    padding-left: 0;
    padding-right: 1.25rem;

    input {
      margin-right: -20px;
    }
  }

  .form-check-input {
    margin-right: 0;

    + label {
      margin-right: 1.3rem;
    }
  }

  .bs-tooltip-right {
    .arrow {
      &:before {
        left: auto;
        right: -8px;
      }
    }
  }

  .br-theme-bars-1to10,
  .br-theme-bars-movie,
  .br-theme-bars-reversed,
  .br-theme-bars-square,
  .br-theme-css-stars {
    .br-widget {
      .br-current-rating,
      a {
        float: right;
      }
    }
  }

  .br-theme-bars-reversed .br-widget .br-current-rating {
    text-align: right;
  }

  .tool-container.tool-top .arrow {
    margin-right: -7px;
  }

  .sw-theme-arrows > ul.step-anchor > li:first-child > a {
    padding-right: 15px;
    padding-left: 30px;
  }

  .minicolors .form-control {
    padding: 6px 44px 5px 12px;
  }

  .minicolors-theme-bootstrap .minicolors-swatch {
    right: 3px;
  }

  .minicolors-position-left .minicolors-panel {
    right: 0;
  }

  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    float: right;
  }
  /* =========   Extra pages end ============= */
  @media only screen and (max-width: 768px) {
    .sw-theme-dots {
      > ul.step-anchor {
        &:before {
          right: 1px;
          margin-left: 10px;
          margin-right: 0;
        }

        > li {
          > a {
            &:before {
              right: -23px;
              margin-right: 10px;
            }

            &:after {
              right: -40px;
              top: -45px;
            }
          }
        }
      }
    }
  }

  .flat-card {
    .row-table {
      .br {
        border-right: none;
        border-left: 1px solid #ccc;
      }
    }
  }

  .mce-menu.mce-menu-align {
    .mce-caret,
    .mce-menu-shortcut {
      left: 0;
      right: auto;
    }
  }

  .mce-menu-item {
    .mce-caret {
      margin-left: 6px;
    }
  }

  .ux-designer .btn {
    right: auto;
    left: 20px;
  }

  .owl-carousel {
    direction: ltr;
  }

  .social-widget-card i {
    left: 10px;
    right: auto;
  }

  .proj-t-card .pt-badge {
    left: -35px;
    right: auto;
    padding: 60px 20px 20px 50px;
  }

  .new-cust-card .align-middle .status {
    left: 0;
    right: auto;
  }

  .latest-update-card .card-body .latest-update-box:after {
    right: 11px;
    left: auto;
  }

  .latest-update-card.update-card .card-body .latest-update-box:after {
    right: 18px;
    left: auto;
  }

  .widget-chat-box {
    .send-chat {
      border-radius: 10px 0 10px 10px;

      &:before {
        border-top: 6px solid #b2dbfb;
        border-left: 6px solid #b2dbfb;
        border-right-color: transparent;
        right: -12px;
      }
    }
  }

  .cd-timeline__img {
    i,
    img {
      left: auto;
      right: 50%;
      margin-right: -12px;
    }
  }
  /* new 25-2 */
  .table-card .card-body .table tr td:first-child,
  .table-card .card-body .table tr th:first-child {
    padding-right: 25px;
  }

  .support-bar [class*="col"] {
    border-left: 1px solid #fff;
    border-right: 0;
  }

  .support-bar1 [class*="col"] {
    border-left: 1px solid $theme-border;
    border-right: 0;
  }

  .table-card .row-table .br {
    border-left: 1px solid $theme-border;
    border-right: 0;
  }

  .product-progress-card .pp-cont:after {
    right: -15px;
    left: auto;
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline__block:nth-child(even) .cd-timeline__content {
      float: left;

      &:before {
        right: auto;
        left: 100%;
        border-left-color: #fff;
        border-right-color: transparent;
        filter: drop-shadow(1px 0 1px #e2e5e8);
      }
    }

    .cd-timeline__content {
      &:before {
        right: 100%;
        left: auto;
        border-right-color: #fff;
        border-left-color: transparent;
        filter: drop-shadow(-1px 0 1px #e2e5e8);
      }
    }
  }

  .email-card {
    .email-more-link {
      padding: 8px 25px;
    }

    .nav-pills {
      > li {
        .nav-link {
          i {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }
  }

  #task-container {
    li {
      &.complete {
        &:before {
          left: 5px;
          right: auto;
        }
      }
    }
  }

  .invoice-total {
    &.table {
      tbody {
        float: left;
        padding-left: 20px;
        padding-right: 0;
      }
    }
  }
  /* timeline */
  @media only screen and (min-width: 756px) {
    .cbp_tmtimeline {
      &:before {
        right: 20%;
        margin-right: -10px;
        left: auto;
        margin-left: 0;
      }

      > li {
        .cbp_tmlabel {
          margin: 15px 25% 0 0;

          &:after {
            left: 100%;
            right: auto;
            border-left-color: #202938;
            border-right-color: transparent;
          }
        }

        &:nth-child(odd) {
          .cbp_tmlabel {
            &:after {
              border-left-color: $primary-color;
              border-right-color: transparent;
            }
          }
        }

        .cbp_tmicon {
          right: 20%;
          left: auto;
          margin: 0 -25px 0 0;
        }

        .cbp_tmtime {
          padding-left: 100px;
          padding-right: 0;

          span {
            float: left;
          }
        }
      }
    }
  }

  .custom-switch {
    padding-right: 2.25rem !important;

    .custom-control-label {
      &:before {
        right: -2.29rem !important;
      }
    }
  }
  /* ========= mobile menu start ============ */
  @media only screen and (max-width: 991px) {
    .pcoded-header {
      .m-header {
        .mobile-menu {
          // right: auto;
        }

        .b-brand {
          margin-left: 0;
          margin-right: 20px;
        }
      }

      .navbar-nav.mr-auto li.nav-item:not(.dropdown) {
        padding-left: 0;
        padding-right: 20px;
      }

      #mobile-header {
        left: 20px;
        right: auto;
      }
    }

    .pcoded-header .ml-auto {
      float: left;
    }

    .pcoded-header > .collapse:not(.show) .mob-toggler:after,
    .pcoded-header > .container > .collapse:not(.show) .mob-toggler:after {
      left: 10px;
      right: auto;
    }

    .pcoded-header > .collapse:not(.show) .ml-auto {
      margin-left: 20px !important;
      margin-right: auto !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .pcoded-header {
      .dropdown {
        .dropdown-menu {
          right: 0;
          left: 0;
          margin-left: auto;
        }
      }
    }
  }
  /* ======== mobile menu and ============== */
  /**====== RTl css end ======**/
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    right: calc(100% - 15px) !important;
  }

  *[id] svg {
    direction: ltr;
  }

  .fixed-button {
    left: 30px;
    right: auto !important;
  }

  .dataTables_scrollHeadInner {
    padding-left: 17px !important;
    padding-right: 0 !important;
  }

  .filter-bar .card-task .task-board {
    float: left;
  }

  .pcoded-navbar {
    &.theme-horizontal {
      .sidenav-horizontal-next,
      .sidenav-horizontal-prev {
        // display: none;
        transform: rotate(180deg);
      }

      .pcoded-inner-navbar {
        .pcoded-hasmenu .pcoded-submenu {
          .pcoded-hasmenu.edge .pcoded-submenu:before {
            right: -32px;
          }

          .pcoded-submenu:before {
            left: -22px;
          }

          .pcoded-submenu a:before {
            left: auto;
            right: 4px;
          }

          a {
            padding: 10px 30px 10px 20px;

            &:before {
              left: auto;
              right: 12px;
            }
          }
        }

        li {
          &.pcoded-hasmenu {
            a {
              &:after {
                right: auto;
                left: 10px;
              }
            }
          }
        }

        > li {
          &:last-child > a {
            margin-right: 0;
            margin-left: 70px;
          }

          &.active,
          &.pcoded-trigger {
            &:after {
              left: auto;
              right: 18px;
            }
          }

          &.pcoded-hasmenu {
            > a:after {
              position: absolute;
              left: -4px;
              top: 13px;
            }

            &.active,
            &.pcoded-trigger {
              &:before {
                padding-left: auto;
                padding-right: calc(50% - 25px);
              }
            }
          }
        }
      }

      ~ .pcoded-header {
        margin-right: 0;
      }

      ~ .pcoded-main-container {
        margin-right: 0;
      }
    }
  }

  .footer-fab {
    right: auto;
    left: 70px;

    .fab-hover {
      ul {
        li {
          a {
            &:after {
              right: auto;
              left: calc(100% + 8px);
            }
          }
        }
      }
    }
  }

  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    li.pcoded-hasmenu
    a:after {
    right: auto;
    position: absolute;
    left: -4px;
    top: 13px;
  }

  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    > app-nav-group
    > app-nav-collapse
    > li.active:after,
  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    > app-nav-group
    > app-nav-collapse
    > li.pcoded-trigger:after,
  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    app-nav-item
    > li.active:after,
  .pcoded-navbar.theme-horizontal
    .pcoded-inner-navbar
    app-nav-item
    > li.pcoded-trigger:after {
    left: auto;
    right: 23px;
  }
}
